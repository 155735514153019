import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AnimatedPage } from './styles'
import { PaymentMethod } from '../app-state/types'
import { SwishForm, StripeForm, DirectStripeForm } from '../form'
import { ArrowBack } from '../icon'
import { useAppDispatch } from '../app-state'

const Payment: React.FC = () => {

  const history = useHistory()
  const dispatch = useAppDispatch()

  const params = useParams<{ type: PaymentMethod }>();

  const content = (): JSX.Element => {
    if (params.type === 'swish') {
      return (
        <SwishForm />
      )
    }

    if (params.type === 'stripe') {
      return (
        <StripeForm />
      )
    }

    if (params.type === 'direct-stripe') {
      return (
        <DirectStripeForm />
      )
    }
  }

  const handleBack = () => {
    dispatch({ type: 'get_swish_result', payload: undefined })
    history.goBack()
  }

  return (
    <AnimatedPage>
      <div className='page'>
        <a onClick={handleBack}>
          <ArrowBack />

        </a>

        {content()}
      </div>
    </AnimatedPage>
  )
}

export default Payment
