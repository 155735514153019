import { Dictionary } from './types'

const en: Dictionary = {
  home: {
    title: 'Hello!',
    form: {
      licensePlate: {
        title: 'Please enter your licence plate number:',
        action: 'continue'
      }
    }
  },
  access: {
    showDoors: 'Open doors',
    title: 'Select door to open',
    open: 'Open door',
    opening: 'Opening...',
    success: 'Welcome! The door is now open.',
    failure: 'We are sorry! The door could not be opened at this point.',
  },
  myParking: {
    howWillYouPay: 'How would you like to pay?',
    nothingToPay: 'Nothing to pay!',
    thanksForVisiting: 'Thanks for visiting!',
    ongoing: 'Ongoing'
  },
  noParking: {
    title: 'Something went wrong!',
    subtitle: 'We can\'t find your licence plate number in our system',
    attention: 'Verify that you have given us the correct licence plate number',
    button: 'try again',
    reasons: {
      title: 'The reason could be one of following:',
      list: [
        'You have parked within a free period of time for this facility',
        'There already is an existing parking permit, which will give you right to park here',
      ]
    },
    pleaseRetryTomorrow: 'If you dont see your session here yet, it could be because it is being manually verified. Don\'t worry, your 48\xa0hours to pay starts from when we have verified your session. Please check again tomorrow.',
    supportTitle: 'Do you have questions or concerns?'
  },
  support: {
    title: 'Do you have questions or concerns?',
    call: 'Call support: ',
    email: 'Send an email to our support team',
  },
  session: {
    listTitle: 'Sessions',
    detailsTitle: 'Overview',
    checkin: 'Checkin',
    checkout: 'Checkout',
    price: 'Price',
    ongoing: 'Ongoing',
    validUntil: '(exit not later than: {{time}})',
    startTime: 'Start time',
    endTime: 'End time',
    toPay: 'To pay',
    location: 'Location',
    zone: 'Zone',
    pricingInformation: 'Pricing information',
  },
  payment: {
    title: 'Choose payment method',
    action: 'pay {{amount}} SEK inc. VAT',
    success: 'Payment complete. Thank you for staying with us!',
    failure: 'The was an error processing your payment; go back and try again after 30 minutes. IMPORTANT: to avoid double payment, please check through your banking records that there was no successful payment.  If you believe your payment has been successful or further attempts fail, please contact support.',
    declined: 'The payment was declined.',
    timedOut: 'Session timed out',
    canceled: 'Payment canceled',
    ongoing: 'Waiting for transaction to complete...',
    error: 'There was an error. Check your account balance and please contact us if no transaction has been made',
    registering: 'Registering your payment and preparing your receipt.',
  },
  swish: {
    title: 'Pay with Swish',
    tel: 'Your phone number',
    action: 'Pay {{amount}} SEK inc. VAT',
    open: {
      title: 'Please open the Swish app and approve the payment.',

    }
  },
  stripe: {
    title: 'Card Payment',
    action: 'Pay {{amount}} inc. VAT',
  },
  date: {
    today: 'today',
    yesterday: 'yesterday'
  },
  email: {
    title: 'Would you like a receipt?',
    placeholder: 'john.doe@domain.com',
    action: 'Send receipt',
    sent: 'Receipt sent.',
    label: 'Your email address',
  },
  footer: {
    terms: 'Terms & Conditions'
  },
}

export default en
