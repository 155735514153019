import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EmailForm from '../form/email'
import { useAppDispatch } from '../app-state';
import api from '../../api'



const StripeCompletion: React.FC = () => {

  const [translate] = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const paymentIntentId = query.get('payment_intent');
  const parkingBillId = query.get('bill');
  const statusFromQueryString = query.get('redirect_status');
  const [status, setStatus] = useState('loading');
  const [backendStatus, setBackendStatus] = useState('pending');
  const dispatch = useAppDispatch()


  useEffect(() => {
    // Dispatch email to app state
    dispatch({ type: 'set_email', payload: query.get('email') || '' });

  }, [dispatch]);

  useEffect(() => {

    // Get status for page title direct from the Stripe redirect query string 
    switch (statusFromQueryString) {
      case 'succeeded':
        setStatus('success');
        break;
      case 'failed':
        setStatus('failure');
        break;
      default:
        setStatus('error');
        break;
    }

  }, [statusFromQueryString, query]);

  useEffect(() => {
    if (backendStatus == 'success') return;

    // Poll the backend for our record of the status change
    const fetchPaymentStatus = async () => {
      try {
        const clientId = '00000000-0000-0000-0000-000000000000'; // Backend doesn't care
        const result = await api.payment.getPaymentType(clientId, parkingBillId);
        console.log('API payment status result: ', result);
        setBackendStatus(result["PaymentService"]["status"].toLowerCase() || 'error');
      } catch (err) {
        console.error('API payment status error: ', err);
        setBackendStatus('error');
      }
    };

    fetchPaymentStatus();

  }, [parkingBillId]);

  return (
    <div style={{ marginTop: '75px' }}>
      {/* <h3>Stripe Completion</h3> */}
      {<h3 style={{ textAlign: 'center' }}>
        {translate(`payment.${status}`)}
      </h3>}
      {backendStatus === 'error' && paymentIntentId && <p>{translate('payment.error')}</p>}
      {backendStatus === 'canceled' && paymentIntentId && <p>{translate('payment.canceled')}</p>}
      {backendStatus === 'failure' && paymentIntentId && <p>{translate('payment.failure')}</p>}
      {backendStatus === 'pending' && paymentIntentId && <label>{translate('payment.registering')}<br></br><progress></progress></label>}
      {backendStatus === 'success' && paymentIntentId && <EmailForm bill={parkingBillId} />}
      <pre></pre>

    </div>
  )

}

export default StripeCompletion
