import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import React from 'react'
import { useAppState, useAppDispatch } from '../app-state'
import { useTranslation } from 'react-i18next'



import { useStripe, useElements } from '@stripe/react-stripe-js';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [translate] = useTranslation()
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch()
  // const [email, setEmail] = useState('loading');
  const appState = useAppState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/stripe-completion?bill=${appState.billId}&email=${appState.email}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        onChange={(event) => {
          console.log('setting email to', event.value.email)
          dispatch({ type: 'set_email', payload: event.value.email });
        }}
      //
      // Prefill the email field like so:
      // options={{defaultValues: {email: 'foo@bar.com'}}}
      />
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit" style={{ width: '100%' }}>
        <span id="button-text" style={{ textTransform: 'uppercase' }}>
          {isLoading ? <div className="spinner" id="spinner" style={{ margin: '0' }}></div> : translate('payment.action', { amount: appState.total.sum || 0 })}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form >
  )
}
