// We only need the uuid
class ParkingBill {

  private _id: string
  private _stripePaymentIntentId?: string

  constructor (data: any) {
    this._id = data.id
    this._stripePaymentIntentId = data.stripePaymentIntentId || null
  }

  static fromResponse (data: any): ParkingBill {
      return new ParkingBill(data)
  }

  get id (): string {
    return this._id
  }

  get stripePaymentIntentId(): string {
    return this._stripePaymentIntentId
  }

}

export default ParkingBill
