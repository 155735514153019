import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useAppState, useAppDispatch, } from '../app-state'
import config from '../../config'
import '../stripe/App.css';
import Payment from '../stripe/Payment'

// import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';


const DirectStripe: React.FC = () => {

  const [translate] = useTranslation()
  const state = useAppState()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const params = useParams<{ licensePlate: string }>()
  const [stripePromise, setStripePromise] = useState(null)


  useEffect(() => {
    if (state.sessions.length === 0) {
      history.push(`/my-parking/${params.licensePlate}`)
    } else {
      // initiate direct stripe
      let stripeConfigUrl = `${config.serverUrl()}/stripe/config`;
      console.log('stripeConfigUrl', stripeConfigUrl);
      fetch(stripeConfigUrl).then(async (r) => {
        const { publishableKey } = await r.json();
        setStripePromise(loadStripe(publishableKey, { stripeAccount: 'acct_1L14IK2EW3ud8zdC' }));
      });
      // initiateStripe(
      //   state.licensePlate.replace(' ', ''),
      //   state.selected,
      //   {
      //     expectedPrice: state.total.fractions,
      //     redirectUrl: `${window.location.origin}/result/`,
      //   },
      //   dispatch,
      //   state.clientId
      // )
    }
  }, [])


  return (
    <Payment stripePromise={stripePromise} />
  )
}

export default DirectStripe
