import { PaymentService } from './types'
import { SwishResult, SwishBody, StripeBody, StripeResult } from '../types'
import { ClientId, SessionId, ParkingBill } from '../models'
import apiClient from './api-client'
import { poll } from './utils'
import { PaymentType } from '~components/form/types'


const service: PaymentService = {

  initiateSwishPayment: async (clientId: ClientId, data: SwishBody): Promise<SwishResult> => {

    await apiClient.post(`/clients/${clientId}/pay/swish`, data)

    let tries = 0

    const pollStatus = async () => {
      tries += 1
      const res = await apiClient.get(`/clients/${clientId}/pay/swish/status?billId=${data.parkingBillId}`)
      return res.data
    }

    let res = await poll<SwishResult>(
      pollStatus,
      (res: SwishResult) => res.outcome === 'ongoing' && tries < 30,
      3000
    )

    return res
  },

  getPaymentType: async (clientId: ClientId, parkingBillId: string): Promise<PaymentType> => {
    let tries = 0;

    const pollStatus = async (): Promise<PaymentType> => {
      const res = await apiClient.get(`/clients/${clientId}/parking_bills/${parkingBillId}/status`);
      return res.data as PaymentType;
    };

    const isPending = (paymentType: PaymentType): boolean => {
      if (typeof paymentType === 'object' && 'PaymentService' in paymentType) {
        return paymentType.PaymentService.status === 'Pending';
      }
      return false; // Polling only applies to PaymentService with Pending status
    };

    let paymentType: PaymentType = await poll<PaymentType>(
      pollStatus,
      (paymentType: PaymentType) => isPending(paymentType) && tries++ < 30,
      3000 // Poll every 3 seconds
    );

    return paymentType;
  },

  initiateStripePayment: async (clientId: ClientId, data: StripeBody): Promise<StripeResult> => {
    const res = await apiClient.post(`/clients/${clientId}/pay/card`, data)
    return res.data
  },

  createParkingBill: async (clientId: ClientId, isDirectStripeFlow: boolean, data: { licensePlate: string, sessions: SessionId[] }): Promise<ParkingBill> => {
    const query = isDirectStripeFlow ? '?is_stripe_flow=true' : '?is_stripe_flow=false';
    const res = await apiClient.post(`/clients/${clientId}/parking_bills${query}`, data)
    return ParkingBill.fromResponse(res.data)
  },

  sendReceipt: async (clientId: ClientId, billId: string, email: string): Promise<boolean> => {
    const res = await apiClient.post(`/clients/${clientId}/parking_bills/receipt`, { billId, email })
    return res.status === 200
  },

}

export default service

