import Home from './home'
import MyParking from './my-parking'
import NoParking from './no-parking'
import Payment from './payment'
import PaymentResult from './payment-result'
import StripeCompletion from './stripe-completion'

export {
  Home,
  MyParking,
  NoParking,
  Payment,
  PaymentResult,
  StripeCompletion
}
