import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'


import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import { useAppState, useAppDispatch, initiateDirectStripe } from '../app-state'


function Payment(props) {
  const { stripePromise } = props;
  const [clientSecret, setClientSecret] = useState('');
  const [translate] = useTranslation()
  const state = useAppState();
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    initiateDirectStripe(
      state.licensePlate.replace(' ', ''),
      state.selected,
      {
        expectedPrice: state.total.fractions,
        redirectUrl: `${window.location.origin}/result/`,
      },
      dispatch,
      state.clientId,
      setClientSecret
    )
      // // Create PaymentIntent as soon as the page loads
      // fetch("http://localhost:8081/api/v1/stripe/create-payment-intent")
      .then((res) => { console.log('res', res); return res; })
    // .then(({ stripePaymentIntentId }) => setClientSecret(stripePaymentIntentId));
  }, []);


  return (
    <>
      <h1>
        {translate('stripe.title')}
      </h1>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default Payment;
