import { Dictionary } from './types'

const sv: Dictionary = {
  home: {
    title: 'Hej!',
    form: {
      licensePlate: {
        title: 'Ange ditt registreringsnummer:',
        action: 'Fortsätt'
      }
    }
  },
  access: {
    showDoors: 'Öppna dörrar',
    title: 'Välj vilken dörr du vill öppna',
    open: 'Öppna dörr',
    opening: 'Öppnar...',
    success: 'Välkommen in! Dörren är nu öppen',
    failure: 'Förlåt! Dörren kunde tyvärr inte öppnas.',
  },
  myParking: {
    howWillYouPay: 'Hur vill du betala?',
    nothingToPay: 'Finns inget att betala!',
    thanksForVisiting: 'Tack för besöket!',
    ongoing: 'Pågående'
  },
  noParking: {
    title: 'Hoppsan!',
    subtitle: 'Vi hittar inte ditt registreringsnummer:',
    attention: 'Kontrollera att du har skrivit in rätt registreringsnummer',
    button: 'Försök igen',
    reasons: {
      title: `Ser du detta felmeddelande **även efter att ha säkerställt att du angivit rätt registreringsnummer?**


>>Du kan känna dig lugn.
Vårt system har helt enkelt inte registrerat en
transaktion på grund av:`,
      list: [
        'Parkeringen har skett under en period som är gratis {{ actionValue }}',
        'Ett parkeringstillstånd finns redan som ger dig rätt att parkera här'
      ]
    },
    pleaseRetryTomorrow: 'Om du fortfarande inte ser din session här kan det bero på att den verifieras manuellt. Oroa dig inte, du har fortfarande 48\xa0timmar på dig att betala från det att sessionen verifierats. Vänligen kom tillbaka imorgon.',
    supportTitle: 'Har du frågor och undrar över något?'
  },
  support: {
    title: 'Har du frågor och undrar över något?',
    call: 'Telefonnummer: {{phone}}',
    email: 'Maila till Kundservice',
  },
  session: {
    listTitle: 'Sessioner',
    detailsTitle: 'Oversikt',
    checkin: 'Incheckning',
    checkout: 'Utcheckning',
    price: 'Pris',
    pricingInformation: 'Prisinformation',
    ongoing: 'Pågående',
    validUntil: '(Kör ut senast: {{time}})',
    startTime: 'Starttid',
    endTime: 'Sluttid',
    toPay: 'Att betala',
    location: 'Plats',
    zone: 'Zon',
  },
  payment: {
    title: 'Välj betalsätt',
    action: 'betala {{amount}} SEK inkl. moms',
    success: 'Tack för din betalning! Välkommen åter.',
    failure: 'Det kan ha blivit ett fel vid betalning, vänta 30 minuter och dubbelkolla på bankkontot att betalning faktiskt gjordes, om du ser betalningen på bankkontot så funkar det, om du inte ser betalningen så försök betala igen.',
    declined: 'Betalningen nekades',
    canceled: 'Betalningen avbröts',
    timedOut: 'Sessionen har gått ut',
    ongoing: 'Väntar på att transaktionen ska slutföras...',
    error: 'Fel inträffade. Vänligen kontrollera ifall pengarna har dragits',
    registering: 'Registrerar din betalning och förbereder ditt kvitto.'
  },
  swish: {
    title: 'Betala med swish',
    action: 'Betala {{amount}} SEK inkl. moms',
    tel: 'Ange ditt telefonnummer',
    open: {
      title: 'Öppna Swish appen och godkänn betalningen.',
    }
  },
  stripe: {
    title: 'Kortbetalning',
    action: 'Betala {{amount}} SEK inkl. moms',
  },
  date: {
    today: 'idag',
    yesterday: 'igår'
  },
  email: {
    title: 'Önskar du kvitto?',
    placeholder: 'john.doe@domain.com',
    action: 'Skicka kvitto',
    sent: 'Kvittot har skickats.',
    label: 'Din e-postadress',
  },
  footer: {
    terms: 'Köpvillkor & Reklamation'
  }
}

export default sv
